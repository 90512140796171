@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}

@keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.deployment-loading {
  position: relative;
  overflow: hidden;
  z-index: 0;

  &::before {
    content: "";
    display: block;
    background: conic-gradient(
      var(--success),
      lime,
      var(--success),
      lime,
      var(--success)
    );
    width: calc(100% * 1.41421356237);
    padding-bottom: calc(100% * 1.41421356237);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    z-index: -2;
    animation: spin 5s linear infinite;
  }

  &::after {
    content: "";
    position: absolute;
    inset: 2px;
    background: var(--background-2);
    z-index: -1;
    border-radius: 0.5rem;
  }
}
