html {
  @media screen and (max-width: 576px) {
    font-size: 12px;
  }
  @media screen and (max-width: 374px) {
    font-size: 10px;
  }
}

body
  > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) {
  display: none;
}
