.kbar-shortcut {
  font-family: monospace;
  background: var(--background-2);
  padding: var(--chakra-space-1) var(--chakra-space-2);
  border-radius: var(--chakra-space-1);
  box-shadow: var(--shadow);
}

.kbar-position {
  z-index: 100000;
  backdrop-filter: blur(0.8px) contrast(0.7);
}
