.monaco-editor-wrapper {
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid;
  height: 100px;
  display: flex;
  position: relative;
  text-align: initial;
  width: 100%;
  resize: vertical;
  border-color: var(--chakra-colors-chakra-border-color);
  box-shadow: none;
  transition: var(--chakra-transition-duration-normal) ease;
  transition-property: border-color, box-shadow;

  &.error {
    border-color: #fc8181;
    box-shadow: 0 0 0 1px #fc8181;
  }

  &:focus-within {
    border-color: #63b3ed;
    box-shadow: 0 0 0 1px #63b3ed;
  }
}

.disabled-editor {
  opacity: 0.7; /* Adjust the opacity to visually indicate disabled state */
  filter: grayscale(1);
}
