.z-xterm {
  height: 100%;
  width: 100%;
  .xterm {
    height: 100%;
    width: 100%;
  }
}

// only target popout terminals/logs
// https://github.com/xtermjs/xterm.js/pull/1208
.popout-xterm-container .xterm {
  padding-bottom: 24px;
}
