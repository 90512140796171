@import "../index.scss";

.App {
  display: flex;
}

.NavBrand {
  color: #fff;
  font-size: 3rem;
  padding: 0 2rem;

  &:hover {
    color: #aaa;
    opacity: 0.8;
  }
}

#env-upload-button {
  margin-left: 0;

  &:hover {
    cursor: pointer;
  }
}

.NavRight {
  margin-left: auto;

  @media screen and (max-width: 768px) {
    display: none;
  }

  a {
    img:hover {
      opacity: 0.8;
    }
  }

  button {
    margin-right: 2rem;
  }
}

.AppContent {
  padding: 2rem 5rem;

  @media screen and (max-width: 768px) {
    padding: 2rem 2rem;
  }
}

.uploadcare--widget__button.uploadcare--widget__button_type_open {
  display: inline-flex;
  appearance: none;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: 2px solid transparent;
  outline-offset: 2px;
  line-height: 1;
  border-radius: var(--chakra-radii-md);
  font-weight: var(--chakra-fontWeights-semibold);
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  box-shadow: var(--chakra-shadows-md);
  height: var(--chakra-sizes-10);
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  font-size: 14px;
  padding-inline-start: var(--chakra-space-4);
  padding-inline-end: var(--chakra-space-4);
  background: var(--background-2);
  border: 1px solid var(--chakra-colors-chakra-border-color);
  color: var(--foreground);
}

.ant-menu-horizontal {
  border-bottom: none;
}

.ant-layout-header {
  @media screen and (max-width: 768px) {
    padding: 0;

    .ant-menu-item {
      padding: 0 1.5rem;
    }
  }
}

.ant-table thead>tr>th,
.ant-table tbody>tr>td,
.ant-table tfoot>tr>th,
.ant-table tfoot>tr>td {
  @media screen and (max-width: 768px) {
    padding: 8px 8px;
  }
}

button.button-flash {
  @keyframes glowing {
    0% {
      background-color: #4cb32b;
    }

    50% {
      background-color: #59cd32;
      box-shadow: 0 0 10px #59cd32;
    }

    100% {
      background-color: #4cb32b;
    }
  }

  animation: glowing 2000ms infinite;
}

// kbar overwrite
html,
body {
  height: 100%;
  overflow: auto !important;
  margin-right: 0 !important;
}

input[readonly] {
  cursor: not-allowed;
  color: var(--secondary);
  opacity: 0.7;
}

@keyframes slideFade {

  0%,
  100% {
    transform: translateY(0);
    opacity: 1;
  }

  25% {
    transform: translateY(-100%);
    opacity: 0;
  }

  50% {
    transform: translateY(-200%);
    opacity: 0;
  }

  75% {
    transform: translateY(200%);
    opacity: 0;
  }
}

@keyframes initialSlideFade {
  0% {
    transform: translateY(200%);
    opacity: 0;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}