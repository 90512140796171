@import "../../App.scss";

select option {
  background-color: #222;
}

.nice-digits {
  width: 50%;
  text-align: center;
}

.textarea-password {
  -webkit-text-security: disc;
}
